
import { CHAIN_IDS } from 'config/web3/chains';

const IMX_ADDRESSES: {
  [chainId: number]: string;
} = {
  [CHAIN_IDS.ROPSTEN]: '0x6659a9c5cd313974343e30b4fdffd95bd4b4dcd2',
  [CHAIN_IDS.KOVAN]: '0x5671B249391cA5E6a8FE28CEb1e85Dc41c12Ba7D',
  // [CHAIN_IDS.ETHEREUM_MAIN_NET]: '0x7b35ce522cb72e4077baeb96cb923a5529764a00',
  [CHAIN_IDS.ETHEREUM_MAIN_NET]: '0xF655C8567E0f213e6C634CD2A68d992152161dC6',
  [CHAIN_IDS.ARBITRUM]: '0x56659245931cb6920e39c189d2a0e7dd0da2d57b',
  // [CHAIN_IDS.MATIC]: '0x60bb3d364b765c497c8ce50ae0ae3f0882c5bd05'
  [CHAIN_IDS.MATIC]: '0xf972daced7c6b03223710c11413036d17eb298f6'
};

const IMX_DECIMALS = 18;

export {
  IMX_ADDRESSES,
  IMX_DECIMALS
};
