
import { CHAIN_IDS } from 'config/web3/chains';

const STAKING_ROUTER_ADDRESSES: {
  [chainId: number]: string;
} = {
  [CHAIN_IDS.ARBITRUM]: '0xB323c5bcb066eA69d90ac21d74b714B52F0679b0'
};

export {
  STAKING_ROUTER_ADDRESSES
};
